@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* fluid typography from utopia calculator */
$font-sm: clamp(0.91rem, calc(0.89rem + 0.10vw), 0.96rem);
$font-base: clamp(1.09rem, calc(1.05rem + 0.21vw), 1.20rem);
$font-lg: clamp(1.31rem, calc(1.24rem + 0.37vw), 1.50rem);
$font-xl: clamp(1.58rem, calc(1.46rem + 0.59vw), 1.88rem);
$font-2xl: clamp(1.89rem, calc(1.71rem + 0.89vw), 2.34rem);
$font-3xl: clamp(2.27rem, calc(2.01rem + 1.29vw), 2.93rem);
$font-4xl: clamp(2.72rem, calc(2.36rem + 1.83vw), 3.66rem);
$font-5xl: clamp(3.27rem, calc(2.75rem + 2.56vw), 4.58rem);

/* variables */
$font-default: Outfit;
$bcg: #171819;
$muted: #C9CBCF;
$accent: #66CCC1;
$blue: #00799e;


@mixin drop-sm {
	filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.90))
}
@mixin drop-md {
	filter: drop-shadow(1px 2px 0px rgba(0, 0, 0, 0.90))
}
@mixin drop-lg {
	filter: drop-shadow(1px 4px 0px rgba(0, 0, 0, 0.90));
}

@mixin container {
	margin: 0 auto;
	padding: 1rem;
	@media screen and (min-width: 640px) {
		padding: 2rem;
	}
	@media screen and (min-width: 1024px) {
		padding: 4rem;
	}
	@media screen and (min-width:1280px){
		padding: 5rem;
	}
	@media screen and (min-width: 1536px) {
		padding: 6rem;
	}

}

@mixin perspective {
	perspective: 100px;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100vh;
}

@mixin parallax {
	position: absolute;
	top: 0;
	left: 0;
	transform: translateZ(-100px) scale(2);
}

@mixin highlight {
	&::selection {
		background-color: $accent;
		color: $bcg;
	}
	&::-moz-selection{
		background-color: $bcg;
		color: $bcg;
	}
}



/* universal border-box sizing */
*,
::before,
::after {
	box-sizing: border-box;
}

html {
	line-height: 1.15; /* 1 */
	-webkit-text-size-adjust: 100%; /* 2 */
	-moz-tab-size: 4; /* 3 */
	tab-size: 4; /* 3 */
}

body {
	margin: 0; /* 1 */
	font-family:
		system-ui,
		-apple-system, /* Firefox supports this but not yet `system-ui` */
		'Segoe UI',
		Roboto,
		Helvetica,
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji'; /* 2 */
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit; /* 1 */
	font-size: 100%; /* 1 */
	line-height: 1.15; /* 1 */
	margin: 0; /* 2 */
}


button,
select {
	text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

:-moz-focusring {
	outline: 1px dotted ButtonText;
}


:-moz-ui-invalid {
	box-shadow: none;
}

/**
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to 'inherit' in Safari.
*/

::-webkit-file-upload-button {
	-webkit-appearance: button; /* 1 */
	font: inherit; /* 2 */
}

/*
Custom styling content
======================
*/

html {
	scroll-behavior: smooth;
}

html, body {
	overflow: hidden;
	height: 100%;
}
body {
	@include highlight();
}

.app {
	@include perspective();
}

.parallax{
	@include parallax();
	z-index: -100;
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: flex-start;
	img {
		max-width: 250%;
		object-fit: cover;
		@media screen and (min-width: 600px) {
			max-width: 120%;	
		}
	}
}



.header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	.header-text {
		font-weight: bold;
		color: white;
		text-align: center;
		letter-spacing: 0.025rem;
		h1{
			animation: 1300ms ease-out 0s backwards slideUp;

		}
		p {
			animation: 1500ms ease-out 1300ms backwards fadeIn;
		}
		@media screen and (max-width: 480px) {
			h1{
				font-size: $font-2xl;
				@include drop-sm();
				}
			p {
				font-size: $font-lg;
				@include drop-sm();
				}
			}
		}
		
		@media screen and (min-width: 481px) {
			h1{
				font-size: $font-3xl;
				@include drop-sm();
			}
			p {
				font-size: $font-xl;
				@include drop-sm();
			}
		}
		@media screen and (min-width: 640px) {
			h1{
				font-size: $font-4xl;
				@include drop-md();
			}
			p {
				font-size: $font-2xl;
				@include drop-md();
			}
		}
		@media screen and (min-width: 1024px) {
			h1{
				font-size: $font-5xl;
				@include drop-lg();
			}
			p {
				font-size: $font-3xl;
				@include drop-lg();

			}
		}
	a {
		background-color: rgb(0, 121, 158);
		color: white;
		text-decoration: none;
		font-size: $font-base;
		font-weight: 500;
		padding: 1rem 2rem;
		border-radius: 9999px;
		border: 2px solid $bcg;
		display: flex;
		align-items: center;
		animation: 700ms ease-out 2000ms backwards slideBounce;
		transition-duration: 100ms;
	}
	a:focus {
		outline: none;
	}
	a:focus-visible {
		box-shadow: 0 0 0 4px $bcg;
		outline: $accent solid 4px; 
		outline-offset: 4px;
		box-sizing: 0 0 0 4px $bcg;
	}
	a:hover {
		box-shadow: 0 0 0 4px $bcg;
		outline: $accent solid 4px; 
		outline-offset: 4px;
		background-color: $accent;
		color: rgb(77, 77, 77);

	}
	a svg {
		margin-left: 1rem;
	}
}

@keyframes slideUp {
	from {
		transform: translateY(60vh);
		opacity: 0;
	}
	to {
		transform: translateY(0%);
		opacity: 1;
	}
}
@keyframes slideBounce {
	0% {
		transform: translateY(30vh);
		opacity: 0;
	}
	70%{
		transform: translateY(-2vh);
		opacity:0.9
	}

	to {
		transform: translateY(0%);
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.main {
	position: relative;
	margin-top: 4rem;
	//margin-bottom: -4rem;
	//padding-bottom: 4rem;
	// padding and margin off set for fading, will allow to scale up without beong cut off

	@media screen and (min-width: 640px){
		margin-top: 6rem;
		gap: 6rem;
	}
	@media screen and (min-width: 1024px) {
		margin-top: 8rem;
		gap: 1rem;
	}
	overflow:hidden;


	section{
		//opacity: 0;
		//transform: translateY(-2rem);
		transition: all 500ms;
	}
	section.fade-up{
		opacity:1;
		transform: translateY(2rem);
	}
}
.main .gradient-bottom {
	position: absolute;
	bottom: 0;
	height: 75%;
	width: 100%;
	z-index: -10;
	background-image: linear-gradient(transparent, 20%, $bcg);
}

.main section.intro {
	margin: 0 auto 6rem;
	text-align: center;
	max-width: 75ch;
	h1 {
			font-size: $font-sm;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $accent;
			@include drop-sm();
	}
	h2 {
			letter-spacing: 0.025em;
			font-weight: 700;
			@include drop-md();			

	}
	p {
			color: whitesmoke;
			line-height: 1.5;
			padding: 2rem;
			text-align: justify;
			@include drop-sm();
	}
	@media screen and (max-width: 639px) {
		h1 {
			font-size: $font-sm;
		}
		h2{
			font-size: $font-2xl;
		}
		p {
			font-size: $font-sm;
		}
	}
	@media screen and (min-width: 640px) {
		h1 {
			font-size: $font-lg;
		}
		h2{
			font-size: $font-3xl;
		}
		p {
			font-size: $font-base;
		}

	}
	@media screen and (min-width: 1024px) {
		h1 {
			font-size: $font-xl;
		}
		h2{
			font-size: $font-4xl;
		}
		p {
			font-size: $font-lg;
		}
		
	}
	button {
		background-color: rgb(0, 121, 158);
		color: white;
		text-decoration: none;
		font-weight: 500;
		padding: 0.5rem;
		border-radius: 0 999px 999px 0;
		border: 2px solid $bcg;
		margin: -1rem 2rem 1rem auto;
		display: flex;
		align-items: center;
		transition-duration: 100ms;
		@media screen and (max-width: 480px) {
			transform: scale(2);
			border-radius: 50%;

		}

	}
	button:focus {
		outline: none;
	}
	button:focus-visible {
		box-shadow: 0 0 0 4px $bcg;
		outline: $accent solid 4px; 
		outline-offset: 4px;
		box-sizing: 0 0 0 4px $bcg;
	}
	button:hover {
		box-shadow: 0 0 0 4px $bcg;
		outline: $accent solid 4px; 
		outline-offset: 4px;
		background-color: $accent;
		color: rgb(77, 77, 77);

	}
	button svg {
		margin-left: 1rem;
		width: 1rem;
		@media screen and (max-width: 480px) {
			width: 0.5rem;
			margin-left: 0;
		}
	}
	button span{
		@media screen and (max-width: 480px) {
			display: none;
		}
	}
	
}
.slider-section-wrapper{
	margin-bottom: 6rem;
}
section.slider {
		@include container();
		display: grid;
		gap: 1rem;
		text-align: center;
		position: relative;			
		h1 {
			font-size: $font-sm;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $accent;
			@include drop-sm()

		}
		h2 {
			letter-spacing: 0.025em;
			font-weight: 700;
			@include drop-sm();
		}
		@media screen and (min-width:640px) {
			text-align: left;

		}
	}
section.slider .slider-control{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		p {
			color: whitesmoke;
			font-size: $font-sm;
			line-height: 1.5;
			padding: 2rem;
			text-align: justify;
			@include drop-sm();
			max-width: 42rem;
		}
		.buttons{
			display: flex;
			align-items: center;
			gap: 1rem;
			padding: 1rem;
			button[data-arrow="prev"] {
					@media screen and (max-width: 480px) {
						position: absolute;
						left: 9px;
						top: 540px;
						z-index: 100;
					}
			
				}
			
			button[data-arrow="next"] {
					@media screen and (max-width: 480px) {
						position: absolute;
						right: 9px;
						top: 540px;
						z-index: 100;
					}
				}
		}
		.buttons>*{
			display:grid;
			place-items: center;
			padding: 0.5rem;
			border-radius: 9999px;
			border: none;
			background-color: $bcg;
			opacity: 0.5;
			&:hover{
				opacity: 0.8;
				background-color: $accent;
			}
			&:focus {
				outline:none;
			}
			&:focus-visible {
				box-shadow: 0 0 0 4px $accent;			
			}
			&:disabled{
				opacity: 0.2;
				background-color: $bcg;
			}
		}
		.buttons>*>svg{
			pointer-events: none;
		}
		@media screen and (min-width:640px) {
			flex-wrap: no-wrap;
			justify-content: space-between;

		}
	}
	.slides-wrapper {
		margin-top: -3rem;
		@include container();
		@media screen and (max-width: 480px) {
			width: 100vw;
			
		}
		@media screen and (min-width:640px) {
			margin-top: -4rem;
		}
		@media screen and (min-width: 1024px) {
			margin-top: -9rem;
		}
		div {
			display: flex;
			transition: transform 500ms;
		}
		div>.slide{
			position:relative;
			flex: 1 0 auto;
			padding-right: 1rem;
			@media screen and (max-width:480px) {
				width: auto;
				flex-basis: 24rem;
			}
		}
		div>.slide>p {
			position: absolute;
			bottom: 1rem;
			left: 0.5rem;
			margin: 0;
			padding: 0.5rem 1rem;
			background-color: $bcg;
			font-weight: 700;
			font-size: $font-lg;
			pointer-events: none;
			letter-spacing: 0.025em;
			transition-duration: 300ms;
		}
		div>.slide>img {
			object-fit: cover;
			object-position: center;
			width: 344px;
			height: 410px;
			@media screen and (min-width: 481px) {
				filter: grayscale(1); 
				transition-duration: 300ms;
				&+p {
					opacity: 0;
				}
				&:hover {
					filter: grayscale(0);
					&+p {
						opacity: 1;
					}
				}
			}
		}
		div>.slide>div{
			display: block;
			width: 344px;
			height: 410px;
			font-size: $font-lg;
			font-weight: 700;
			text-align: center;
			padding-top:180px;
			background-color: gray;
			@media screen and (min-width: 481px) {
					filter: grayscale(1);
					transition-duration: 300ms;
			
					&+p {
						opacity: 0;
					}
			
					&:hover {
						filter: grayscale(0);
			
						&+p {
							opacity: 1;
						}
					}
				}
		}

	}
section.contact .contact-header{
	margin: 0 auto;
    text-align: center;
    max-width: 75ch;
	h1 {
		font-size: $font-sm;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $accent;
		@include drop-sm();
}
h2 {
		letter-spacing: 0.025em;
		font-weight: 700;
		@include drop-md();			

}
p {
		color: whitesmoke;
		line-height: 1.5;
		padding: 2rem;
		text-align: justify;
		@include drop-sm();
}
@media screen and (max-width: 639px) {
	h1 {
		font-size: $font-sm;
	}
	h2{
		font-size: $font-2xl;
	}
	p {
		font-size: $font-sm;
	}
}
@media screen and (min-width: 640px) {
	h1 {
		font-size: $font-lg;
	}
	h2{
		font-size: $font-3xl;
	}
	p {
		font-size: $font-base;
	}

}
@media screen and (min-width: 1024px) {
	h1 {
		font-size: $font-xl;
	}
	h2{
		font-size: $font-4xl;
	}
	p {
		font-size: $font-lg;
	}
	
}
}	

section.contact form{
	padding: 1rem;
	margin: 0 auto;
	max-width: 42rem;
	background-color: $bcg;
	border-radius: 1rem;
	box-shadow: 0 0 2rem 0.5rem $bcg;
	div {
		position: relative;
	}
	div>label{
		letter-spacing: 0.125rem;
		position: absolute;
		padding: 0 0.5rem;
		border-radius: 9999px;
		top: -0.5rem;
		left: 3rem;
		font-size: $font-base;
		/* uncomment to translate label on focus
		top: 0.5rem;
		left: 4rem;
		font-size: $font-lg;
		*/
		transition: all 300ms;
		background-color: $bcg;
		color: rgb(199, 199, 199);
	}

	div>input {
		border: 2px solid $blue;
		border-radius: 9999px;
		padding: 1rem;
		margin-bottom: 1rem;
		background-color: transparent;
		width: 100%;
		font-size: $font-base;

		
		&:focus{
			border-color: $accent;
			outline: none;
			+label {
			/* uncomment to translate label on focus
			top: -0.5rem;
			left: 3rem;
			font-size: $font-base;
			*/
			color: $accent;
			}
		}
	}
	.message{
		margin-top: 1rem;
	}
	textarea{
		border: 2px solid $blue;
		border-radius: 1rem;
		width: 100%;
		padding: 1rem;
		background-color: transparent;
		&:focus{
			border-color: $accent;
			outline: none;
			+label {
			top: -0.5rem;
			left: 3rem;
			font-size: $font-base;
			color: $accent;
			}
		}
	}
	button {
		background-color: rgb(0, 121, 158);
		color: white;
		text-decoration: none;
		font-weight: 500;
		margin: 1rem auto;
		padding: 1rem 2rem;
		border-radius: 9999px;
		border: 2px solid $bcg;
		display: flex;
		align-items: center;
		transition-duration: 100ms;
	}
	button:focus {
		outline: none;
	}
	button:focus-visible {
		box-shadow: 0 0 0 4px $bcg;
		outline: $accent solid 4px; 
		outline-offset: 4px;
		box-sizing: 0 0 0 4px $bcg;
	}
	button:hover {
		box-shadow: 0 0 0 4px $bcg;
		outline: $accent solid 4px; 
			outline-offset: 4px;
		background-color: $accent;
		color: rgb(77, 77, 77);

	}
	button span{
		@include drop-md();
	}
	button svg {
		margin-left: 1rem;
		width: 1rem;
		@media screen and (max-width: 480px) {
			width: 0.5rem;
			margin-left: 0;
		}
	}
}
section.contact .submit-success{
	margin: 0 auto;
	max-width: 42rem;
	text-align: center;
	h2 {
		letter-spacing: 0.025em;
		font-weight: 700;
		color: $accent;	

}
p {
		color: whitesmoke;
		line-height: 1.5;
		padding: 2rem;
		text-align: center;
		@include drop-sm();
}
@media screen and (max-width: 639px) {
	h2{
		font-size: $font-2xl;
	}
	p {
		font-size: $font-base;
	}
}
@media screen and (min-width: 640px) {
	h2{
		font-size: $font-2xl;
	}
	p {
		font-size: $font-lg;
	}

}
@media screen and (min-width: 1024px) {
	h2{
		font-size: $font-4xl;
	}
	p {
		font-size: $font-xl;
	}
	
}
}	

footer {
	width: 100%;
	margin-top: 2rem;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	justify-content: center;
	align-items: center;
	background-color: black;
	color:rgb(170, 170, 170);
	div {
		font-size: $font-sm;
		@media screen and (min-width:1024px){
			font-size: $font-base;
			letter-spacing: 0.125rem;
			
		}
	}
}
